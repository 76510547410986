















import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ components: {} })
export default class ParallaxImage extends Vue {
    @Prop({ required: true }) src!: string;
    @Prop({ type: Boolean }) dark!: boolean;
    @Prop({ required: false, default: 750 }) height!: number;
    @Prop({ type: Boolean }) center!: boolean;
}
