







import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ components: {} })
export default class ParallaxBlock extends Vue {
    @Prop({ required: true }) title!: string;
    @Prop({ required: true }) subtitle!: string;
}
