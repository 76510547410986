
































































































import { Component, Vue } from "vue-property-decorator";
import ParallaxImage from "./ParallaxImage.vue";
import ParallaxBlock from "./ParallaxBlock.vue";

@Component({ components: { ParallaxImage, ParallaxBlock } })
export default class Home extends Vue {
    get mobile() {
        return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    }
}
